import React, { useState, useEffect } from 'react';

import WarningIcon from '@core/assets/icons/warning.svg';
import TickIcon from '@core/assets/icons/tick-square.svg';
import EyeIcon from '@core/assets/icons/eye.svg';
import EyeSlashIcon from '@core/assets/icons/eye-slash.svg';

import classes from './PasswordInput.module.scss';

const PasswordInput = ({ validation, input, errors, register, className, kind }) => {
	const [value, setValue] = useState('');
	const [type, setType] = useState(true);
	const passwordInput = {
		label: 'Password',
		name: 'password',
		placeholder: 'Password',
		id: 'password',
		autoComplete: 'off',
		...input
	};
	const passwordValidation = {
		required: true,
		pattern: {
			value:
				kind !== 'simple'
					? /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@%+\\/'!#$^?:,(){}[\]~`\-_.])(?=.*^[^<> \t]*$)[a-zA-Z0-9@%+\\/'!#$^?:,(){}[\]~`\-_.]{8,64}$/
					: /^.{8,64}$/
		},
		...validation
	};

	const [validationError, setValidationError] = useState({ init: true });

	const validate = (name, valid) => {
		setValidationError((prevState) => {
			return { ...prevState, [name]: valid };
		});
	};
	useEffect(() => {
		if ((errors && errors[passwordInput.name]) || !validationError.init) {
			validationError.init && validate('init', false);

			value.match(/^.{8,64}$/) ? validate('minLength', true) : validate('minLength', false);
			value.match(/(?=.*[a-z])(?=.*[A-Z])/)
				? validate('caseSensetive', true)
				: validate('caseSensetive', false);
			value.match(/(?=.*[1-9])/) ? validate('number', true) : validate('number', false);
			value.match(/(?=.*[@%+\\/'!#$^?:,(){}[\]~`\-_.])(?=.*^[^<> \t]*$)/)
				? validate('specialChar', true)
				: validate('specialChar', false);
		}
	}, [errors.password, passwordInput.name, value]);

	return (
		<div className={`d-flex flex-column ${classes['passwordinput-wrapper']} ${className || ' '} `}>
			<label htmlFor={passwordInput.id}>{passwordInput.label}</label>
			<div className={`${classes['password-input-wrapper']}`}>
				<input
					onInput={(e) => setValue(e.target.value)}
					className={`${(errors && errors[passwordInput.name] && classes['invalid']) || ''}`}
					{...passwordInput}
					type={type ? 'password' : 'text'}
					{...register(passwordInput.name, passwordValidation)}
				/>
				{type ? (
					<EyeIcon onClick={() => setType(!type)} data-testid="eye-icon" />
				) : (
					<EyeSlashIcon onClick={() => setType(!type)} data-testid="eye-slash-icon" />
				)}
			</div>
			<div className={`${classes['errors-wrapper']}`}>
				{kind !== 'simple' && (
					<>
						<p
							className={`${(validationError.minLength === false && classes['error']) || ''} 
							${(validationError.minLength && !validationError.init && classes['passed']) || ''}`}>
							<span>
								{' '}
								{validationError.minLength === false && <WarningIcon data-testid="warning-icon" />}
								{validationError.minLength && validationError !== 'init' && (
									<TickIcon data-testid="tick-icon" />
								)}
							</span>
							Should be at least 8 characters
						</p>
						<p
							className={`${(validationError.caseSensetive === false && classes['error']) || ''} 
					${(validationError.caseSensetive && !validationError.init && classes['passed']) || ''}`}>
							<span>
								{' '}
								{validationError.caseSensetive === false && (
									<WarningIcon data-testid="warning-icon" />
								)}
								{validationError.caseSensetive && validationError !== 'init' && (
									<TickIcon data-testid="tick-icon" />
								)}
							</span>
							Should contain at least 1 uppercase and lowercase letter
						</p>
						<p
							className={`${(validationError.number === false && classes['error']) || ''} 
					${(validationError.number && !validationError.init && classes['passed']) || ''}`}>
							<span>
								{' '}
								{validationError.number === false && <WarningIcon data-testid="warning-icon" />}
								{validationError.number && validationError !== 'init' && (
									<TickIcon data-testid="tick-icon" />
								)}
							</span>
							Should contain at least 1 number
						</p>
						<p
							className={`${(validationError.specialChar === false && classes['error']) || ''} 
					${(validationError.specialChar && !validationError.init && classes['passed']) || ''}`}>
							<span>
								{' '}
								{validationError.specialChar === false && (
									<WarningIcon data-testid="warning-icon" />
								)}
								{validationError.specialChar && validationError !== 'init' && (
									<TickIcon data-testid="tick-icon" />
								)}
							</span>
							Should contain at least 1 special character (@, !, #, etc). Do not use “&#60;” or
							“&#62;” characters
						</p>
					</>
				)}
				{kind === 'simple' && errors && errors[passwordInput.name] && (
					<p
						className={`${classes['error']} 
					${(validationError.minLength && classes['passed']) || ''}`}>
						<span>
							<WarningIcon data-testid="warning-icon" />
						</span>
						Passwords should be at least 8 characters
					</p>
				)}
			</div>
		</div>
	);
};

export default PasswordInput;
