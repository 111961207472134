import React from 'react';

import { useRouter } from 'next/router';
import UseActivities from 'hooks/use-activities';

import Link from 'next/link';

import { useForm } from 'react-hook-form';
// Request Dependency
import { useMutation, useQuery } from 'react-query';
import { loginService } from 'services/auth';
import { initService } from 'services/core';
import { setAuthorization } from 'utils/authentication';

import useDeviceIdentify from 'hooks/use-deviceidentify';
// Toasting error Response
import UseToast from 'hooks/use-toast';
// Custom Components
import TextInput from '@core/components/textinput/TextInput';
import PasswordInput from '@core/components/passwordinput/PasswordInput';
import ActionButton from '@core/components/actionbutton/ActionButton';
import BackButton from '@core/components/backbutton/BackButton';
import GoogleButton from 'components/pages/auth/googlebutton/GoogleButton';
import FacebookButton from 'components/pages/auth/facebookbutton/FacebookButton';
import AppleButton from 'components/pages/auth/applebutton/AppleButton';

import { NextSeo } from 'next-seo';
import { generateSeoProps } from 'router/seoprops/generateSeoProps';

import classes from './Login.module.scss';

const Login = () => {
	const { handleDeviceIdentification } = useDeviceIdentify();
	const { sendUTM } = UseActivities();
	const router = useRouter();
	const nextPage = router?.state?.next || router?.query?.next;
	const { toast, ToastContainer } = UseToast();
	const { data: initData } = useQuery('app-init', initService);
	const { mutate: callLoginApi, isLoading } = useMutation(loginService, {
		onSuccess: (data) => {
			setAuthorization(data.data);
			handleDeviceIdentification();
			sendUTM();
			nextPage ? router.replace(nextPage) : router.replace('/');
		},
		onError: () => {
			toast('Email and / or password do not match', 'Alert');
		}
	});

	// setup UseForm
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({ mode: 'onChange', delayError: 1000 });

	const onSubmit = (value) => {
		callLoginApi(value);
	};

	const seoProps = generateSeoProps({
		title: 'Pharmuni | Login',
		canonical: 'login'
	});

	return (
		<>
			<NextSeo {...seoProps} />
			<ToastContainer />
			<div className={`${classes['login-wrapper']} d-flex flex-column align-items-center `}>
				<div className={`${classes['login']}  d-flex flex-column align-items-stretch`}>
					<BackButton type="text" className="d-none d-md-flex my-4" />
					<h1>Login</h1>
					<div
						className={`${classes['social-login']} d-flex flex-column aling-items-center justify-content-center gap-4`}>
						<GoogleButton hidden={isLoading || !initData?.data?.oauth?.google}>Continue with Google</GoogleButton>
						<AppleButton hidden={isLoading || !initData?.data?.oauth?.apple}>Continue with Apple</AppleButton>
						<FacebookButton hidden={isLoading || !initData?.data?.oauth?.facebook} >Continue with Facebook</FacebookButton>
					</div>
					<div className={`${classes['divider']} my-4 py-2`}>or continue with</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<TextInput
							validation={{
								required: true,
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Please enter a correct email address'
								}
							}}
							input={{
								type: 'email',
								label: 'Email Address',
								name: 'email',
								placeholder: 'Email',
								id: 'email',
								autoComplete: 'off'
							}}
							errors={errors}
							register={register}
						/>
						<PasswordInput kind="simple" errors={errors} register={register} />
						<Link className="d-flex justify-content-center my-4" href="/forgot-password">
							Forgot password ?
						</Link>
						<ActionButton type="submit" disabled={isLoading} loading={isLoading}>
							Login
						</ActionButton>
					</form>
					<p className={`d-flex justify-content-center my-4`}>
						Not registered? <Link href="/register"> Register</Link>
					</p>
				</div>
			</div>
		</>
	);
};

export default Login;
